import React from "react";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import LosMuetros from "../images/Los_Muertos.png";
import LosMuetros2 from "../images/Muertos.png";
// import Banda from "../images/BandaClassLatest.jpg";
import nocheBuena from "../images/nocheBuena.jpg";
import rfKidsShow from "../images/rfKidsShowcase.jpg";
import rfMambo from "../images/rfMamb0Shines.jpg";
import Banda from "../images/latestBanda.jpg";
import nextIcon from "../images/icons8-next-50.png";

import { Link } from "gatsby";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "News",
    link: "news",
  },
];

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: LosMuetros2,
    };
  }

  handleImageSwitch = () => {
    this.setState((prevState) => ({
      currentImage:
        prevState.currentImage === LosMuetros2 ? LosMuetros : LosMuetros2,
    }));
  };

  render() {
    return (
      <Layout
        title="News | RF Dance"
        pathname="news/"
        noHero="no-hero"
        className="news_page"
      >
        <Hero titleRed={`RF`} titleWhite={`News`} />

        <div className="content-block">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">
              {/* English */}
              <h3>English:</h3>
              <div className="english-event">
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=12/13/24">
                      <img src={Banda} alt="Banda Class" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class</h3>
                    <div className="news_content_area">
                      <p>
                        <b>Get Ready to Learn some Banda!</b> Join us for a fun
                        Banda Class led by Christopher & Destany on Friday,
                        December 13th at 9:00 pm at RF Dance Center. Secure your
                        pre-sale spot for $20 (Non-Refundable), or pay $30 at
                        the door. RF Members attend for free. Don't just sit
                        around at parties when the banda is playing! No partner
                        is necessary, and all levels are welcome. Stick around
                        after the class for an extra hour of practice and social
                        dancing to Cumbia and Banda! Secure your spot and join
                        the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=12/13/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=12/21/24">
                      <img src={nocheBuena} alt="  nocheBuena " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Noche Buena Social</h3>
                    <div className="news_content_area">
                      <p>
                        <b>Celebrate the Holidays at RF Noche Buena Social!</b>
                      </p>
                      <p>
                        Join us for an unforgettable night of dance, music, and
                        festive vibes on Saturday, December 21st, from 9:00 PM
                        to 2:00 AM at RF Dance Center, 1517 N. Main St, Santa
                        Ana, CA.
                        <br />
                        <b>Agenda:</b> <br />
                        9:00 PM: Banda Class with Christopher & Destany <br />
                        Social Dancing until 2:00 AM with music by DJ Real Deal
                        and DJ Noe
                        <br />
                        <b>Cover:</b>
                        <br />
                        $25 Pre-Sale (Non-refundable)
                        <br />
                        $35 Door Price
                        <br />
                        <b>RF Members:</b> Free Entry from 9:00 PM - 11:00 PM
                        (MUST Pre-Register by Friday, 12/21)
                        <br />
                        <b>Dress Code:</b> Wear your favorite holiday colors and
                        come ready to dance the night away!
                        <br />
                        Don’t miss this festive celebration—grab your tickets
                        early and join us for a memorable night!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=12/21/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Spanish */}
              <h3>Spanish:</h3>
              <div className="spanish-event">
                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=12/13/24">
                      <img src={Banda} alt="Banda Class" />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Clase de Banda</h3>
                    <div className="news_content_area">
                      <p>
                        Prepárate para aprender Banda! Únete a una divertida
                        clase de Banda dirigida por Christopher y Destany el
                        viernes 13 de Diciembre a las 9:00 p.m. en RF Dance
                        Center. Asegura tu lugar en preventa por $20 (no
                        reembolsable), o paga $30 en la puerta. ¡Los miembros de
                        RF asisten gratis! No te quedes sentado en las fiestas
                        cuando suena la banda. ¡No necesitas pareja y todos los
                        niveles son bienvenidos! Quédate después de la clase
                        para una hora extra de práctica y baile social de Cumbia
                        y Banda. ¡Asegura tu lugar y únete a la fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sLevel=4&sView=day&sLoc=1&sTrn=100000150&date=12/13/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=12/21/24">
                      <img src={nocheBuena} alt="  nocheBuena " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Noche Buena Social</h3>
                    <div className="news_content_area">
                      <p>
                        <b>Celebra las Fiestas en el RF Noche Buena Social!</b>
                      </p>
                      <p>
                        Únete a nosotros para una noche inolvidable de baile,
                        música y ambiente estive el Sabado 21 de diciembre, de
                        9:00 PM a 2:00 AM en el RF Dance Center, ubicado en 1517
                        N. Main St, Santa Ana, CA.
                        <br />
                        <b>Agenda:</b> <br />
                        9:00 PM: Clase de Banda con Christopher & Destany <br />
                        Baile social hasta las 2:00 AM con música de DJ Real
                        Deal y DJ Noe
                        <br />
                        <b>Entradas:</b>
                        <br />
                        $25 Preventa (No reembolsable)
                        <br />
                        $35 En la Puerta
                        <br />
                        Miembros de RF: Entrada gratuita de 9:00 PM a 11:00 PM
                        (DEBES registrarte antes del viernes 21 de diciembre)
                        <br />
                        <b>Código de vestimenta: </b> WVístete con tus colores
                        festivos favoritos y prepárate para bailar toda la
                        noche!
                        <br />
                        No te pierdas esta celebración especial—¡asegura tus
                        boletos con anticipación y acompáñanos para una noche
                        inolvidable!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sVT=64&sLevel=8&sView=day&sLoc=1&sTrn=100000131&date=12/21/24">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default News;
